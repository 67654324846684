import { GatsbyBrowser } from "gatsby"
import * as React from "react"

import { CookiesProvider } from "react-cookie"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import "./src/styles/global.css"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LemLlsfAAAAAOZaLTN_9LJJjoubJ05-z4JYTzcp">
    <CookiesProvider>{element}</CookiesProvider>
  </GoogleReCaptchaProvider>
)
