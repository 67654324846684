module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bident - Zahnärzte Elmshorn","short_name":"bident","start_url":"/","background_color":"#0F488C","display":"minimal-ui","icon":"src/images/icon-white.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"050ed683535a1a21116c103833f991f3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8WHDH4","includeInDevelopment":false,"routeChangeEventName":"route-change","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"countryDetection":true,"floatingPreferencesButtonDisplay":false,"gdprAppliesGlobally":false,"lang":"de","siteId":2604060,"whitelabel":true,"cookiePolicyId":10139407,"banner":{"acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0F488C","acceptButtonDisplay":true,"backgroundColor":"#ECF0F0","backgroundOverlay":true,"closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"position":"float-top-center","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0F488C","rejectButtonDisplay":true,"textColor":"#0F488C"}},"googleTagManagerOptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
